import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ImageGrid } from "../components/ImageGrid"
import { Hero } from "../components/Hero"
import { TextColumns } from "../components/TextColumns"
import { StyledFlex } from "../components/StyledFlex"
import { Image } from "../components/Image"

const primroseImage = number => `primrose-${number}.jpg`

const PrimrosePage = () => {
  return (
    <Layout isProjectPage>
      <SEO />

      <Hero>
        960 Primrose
        <Image src={primroseImage(1)} />
      </Hero>

      <TextColumns offset>
        <div></div>
        <div>ETL Architects</div>
        <div></div>
      </TextColumns>

      {[...Array(22)].map((image, index) => (
        <Image style={{ margin: "80px 0px" }} src={primroseImage(index + 1)} />
      ))}
    </Layout>
  )
}

export default PrimrosePage
